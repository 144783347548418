.timer.col.col {
    /* box-shadow: 0 0 50px #224881; */
    transform: scale(.6,.6);
    padding-left: 463px;
    /* border-radius: 50%; */
    color: #224881;
    /* background-color: #444; */
    /* border: 3px solid #222; */
}
/* .timer.col.col:hover {
    padding-left: 46px;
} */
@media screen and (max-width: 767px){
	.timer.col.col {
        padding-left: 34%;
    }
}
@media screen and (max-width: 480px){
	.timer.col.col {
        padding-left: 23%;
    }
}