.card1 {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; 
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 1%;
    cursor: pointer;
  }

  .cardActive1 {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.01s;
    border-radius: 5px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 1%;
    background: #666;
    border: 2px solid rgb(24, 52, 207)  !important;
    color: rgb(24, 52, 207) !important;
    cursor: pointer;
  }

  .cardActive1:hover {
    box-shadow: 0 0px 10px 0 #424db4a8 ;
  }

  .card1:hover {
    box-shadow: 0 0px 10px 0 #424db4a8 ;
  }

  input:focus {
    background-image:none;
  }
  input.focused {
    animation: blink-empty 1.5s infinite;
    background-image:linear-gradient(rgb(214, 195, 26),rgb(97, 180, 49));
    background-position: 1px  center;
    background-repeat: no-repeat;
    background-size:1px 1.2em;
  }
  @keyframes blink-empty {
    10%, 90%{background-size:1px 1.2em;}
    30%, 70% {background-size: 0 1.2em;}
  }