.bg-head{
    background-color: #99263a;
}
.head-font{
    color: #99263a;
}
.navstyle{
    padding-left: 60px;
    padding-right: 60px;
}
/* Animation */
.shadow-animate{
    animation: mymove infinite;
  animation-duration:1s;
}
@keyframes mymove {
    from {box-shadow:0px 0px 5px #5381c7;}
    to {box-shadow:0px 0px 30px #0e6efd;}
  } 
  .right-animate {
    position: relative;
    animation-name: animate-right;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes animate-right {
    0% {
        right: -100%;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}
.left-animate {
    position: relative;
    animation-name: animate-left;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes animate-left {
    0% {
        left: -100%;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}
.top-animate {
    position: relative;
    animation-name: animate-top;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    opacity: 0;
}
.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }

@keyframes animate-top {
    0% {
        top: -100%;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}
 /* animation  */
@media screen and (max-width: 495px){
	.navstyle {
        padding: 0%;
    }
}
@media screen and (max-width: 375px){
    .navbrand{
        padding: 0%;
        margin: 0%;
        width: 80%;
    }
}